$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

$font-family-sans-serif: 'Axiforma', sans-serif;
$dark: #212121;
$body-color: #212121;
$primary: #fba704;

@import '~bootstrap/scss/bootstrap';

.btn {
  font-family: Teko, sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.75rem;
  letter-spacing: 0.1rem;
}

.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: $white;
}

.btn:disabled {
  color: #E0E1E2;
}

.btn:disabled:hover {
  cursor: not-allowed;
}

.form-label {
  margin-top: 0.75rem;
}

.btn {
  margin: 1rem 0;
}

html, body {
  display: flex;
  flex: 1;
  min-height: 100%;
  width: 100%;
}

#endr-forms-ui {
  display: flex;
  flex: 1;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
}
